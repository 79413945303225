<template>
    <div class="content-wrap">
        <div class="content-select">
            <div class="select-content">
                <el-form ref="emailData" :model="emailData" :rules="rules">
                    <el-row>
                        <el-col :span="7">
                            <el-form-item label="邮箱服务商 " prop="emailProvider">
                                <el-select v-model="emailData.emailProvider" placeholder="请选择邮箱服务商"
                                           :disabled="!editMode">
                                    <el-option v-for="item in EmailProvider"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="发件邮箱" prop="emailAccount">
                                <el-input v-model="emailData.emailAccount" placeholder="请输入发件邮箱"
                                          :disabled="!editMode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label="authorizationCodeLabel" :prop="authorizationCode">
                                <el-input v-model="emailData.authorizationCode"
                                          :placeholder="authorizationPlaceholderLabel"
                                          :disabled="!editMode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="handleSave">
                                {{ saveBtnName }}
                            </el-button>
                            <el-button v-show="editMode" type="default" style="margin-left: 10px;"
                                       @click="handleCancel">取消
                            </el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="select-email">
                <div class="email-state">
                    <label>
                        邮件设置说明
                    </label>
                    <span>
                        此设置主要用作开具"电子发票"时发件邮箱的维护
                    </span>
                </div>
                <div class="email-flow">
                    <div class="email-details">
                        <label>
                            腾讯邮箱设置流程：
                        </label>
                        <span>
                            <a href="https://service.mail.qq.com/cgi-bin/help?subtype=1&&id=28&&no=371">https://service.mail.qq.com/cgi-bin/help?subtype=1&&id=28&&no=371</a>
                        </span>
                    </div>
                    <div class="email-details">
                        <label>
                            网易邮箱设置流程：
                        </label>
                        <span>
                            <a href="https://help.mail.163.com/faqDetail.do?code=d7a5dc8471cd0c0e8b4b8f4f8e49998b374173cfe9171305fa1ce630d7f67ac2cda80145a1742516">https://help.mail.163.com/faqDetail.do?code=d7a5dc8471cd0c0e8b4b8f4f8e49998b374173cfe9171305fa1ce630d7f67ac2cda80145a1742516</a>
                        </span>
                    </div>
                    <div class="email-details">
                        <label>
                            office 365邮箱设置流程：
                        </label>
                        <span class="office-details">
                            创建office365邮箱后，使用邮箱与邮箱密码发送邮件。（注：新创建的邮箱第一次发送邮件时，官方会向该邮箱发送邮件校验，验证成功后方可正常使用）
                        </span>
                    </div>
                </div>
            </div>
            <div class="email-img">
                <img src="@/assets/img/email/illustration.png"/>
            </div>
        </div>
    </div>
</template>

<script>
    import regexLib from '@/assets/js/regex-lib';
    import {saveEmail, getEmail} from "@/service/email";

    export default {
        name: "SysFjyx",
        data: function () {
            const checkMailAccount = async (rule, value, callback) => {
                value = value.trim();
                if (!regexLib.Email.test(value)) {
                    return callback(new Error("邮箱格式错误,请输入正确的邮箱！"))
                }
            };
            return {
                emailData: {
                    emailProvider: "",
                    emailAccount: "",
                    authorizationCode: ""
                },
                emailDataCopy: {},
                EmailProvider: this.$store.state.enums["EmailProvider"],
                rules: {
                    emailProvider: [
                        {required: true, message: '请选择邮箱服务商', trigger: 'blur'}
                    ],
                    emailAccount: [
                        {required: true, message: '请输入发件邮箱', trigger: 'blur'},
                        {validator: checkMailAccount, trigger: 'blur'}
                    ],
                    emailAuthorizationCode: [
                        {required: true, message: '请输入授权码', trigger: 'blur'}
                    ],
                    emailPassword: [
                        {required: true, message: '请输入邮箱密码', trigger: 'blur'}
                    ]
                },
                editMode: false
            }
        },
        created() {
            this.getEmailProps();
        },
        computed: {
            saveBtnName() {
                return this.editMode ? '保存' : '编辑';
            },
            authorizationCodeLabel() {
                return this.emailData.emailProvider == "EMAIL_OFF" ? "邮箱密码" : "授权码";
            },
            authorizationPlaceholderLabel() {
                return this.emailData.emailProvider == "EMAIL_OFF" ? "请输入邮箱密码" : "请输入授权码";
            },
            authorizationCode() {
                return this.emailData.emailProvider == "EMAIL_OFF" ? "emailPassword" : "emailAuthorizationCode"
            }
        },
        methods: {
            handleSave() {
                if (this.editMode) {
                    this.submitForm();
                } else {
                    this.editMode = true;
                }
            },
            submitForm() {
                this.$refs["emailData"].validate((valid) => {
                    if (valid) {
                        this.saveEmailProps();
                    }
                });
            },
            /**
             * 获取邮件
             */
            async getEmailProps() {
                const res = await getEmail();
                if (res.success) {
                    if (res.data != null) {
                        this.$nextTick(() => {
                            this.emailData = JSON.parse(JSON.stringify(res.data));
                            this.emailDataCopy = res.data;
                        })

                    }
                }
            },
            /**
             * 保存邮件
             * */
            async saveEmailProps() {
                const res = await saveEmail(this.emailData);
                if (res.success) {
                    this.editMode = false;
                }
            },
            /**
             * 取消重新刷新
             */
            handleCancel() {
                this.emailData = this.emailDataCopy;
                this.editMode = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-email {
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        margin-right: 24px;
        height: 195px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        .email-state {
            margin-bottom: 24px;
            line-height: 52px;
            border-radius: 2px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            display: flex;
            justify-content: space-between;

            label {
                margin-left: 24px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }

            span {
                padding-right: 24px;
                color: #F5222D;
            }

        }

        .email-flow {
            .email-details {
                margin-bottom: 24px;

                label {
                    margin-left: 24px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }
            }

            .office-details {
                color: #666666;

            }
        }
    }

    .content-select {
        display: flex;
        flex-direction: column;
        min-height: calc(100% + 20px);
    }

    .email-img {
        flex: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        img {
            display: flex;
            margin-bottom: 20px;
        }

    }

</style>